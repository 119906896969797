<template>
  <div class="box">
    <div class="jackpot">
      <div class="jackpot-content-number">
        <span class="jackpot-content-number-text" :data-text="$helper.get('rule').currency.symbol">{{
          $helper.get("rule").currency.symbol }}</span>
        <div v-for="item, key in numFormat(number, false, 0)" :key="key" class="jackpot-content-number-item">
          <div class="jackpot-content-number-item-text" v-if="Number(item) >= 0"
            :style="`transform: translateY(-${item}0%);`">
            <span v-for="item, index in 10" :key="index" class="jackpot-content-number-text" :data-text="index">{{
              index
            }}</span>
          </div>
          <div class="jackpot-content-number-item-text jackpot-content-number-text" v-else :data-text="item">{{ item
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nums: "0123456789",
      interval: null,
      number: this.initNumber()
    }
  },
  methods: {
    initNumber() {
      const date = new Date()
      const seconds = date.getSeconds() + date.getMinutes() * 60 // 1-3600
      // 设置伪随机输，根据时间线，算出随机数的和
      // 10-1500
      const randomArr = [1230.36, 1120.61, 1850.68, 106.58, 508.36, 458.51, 1890.61, 623.15]
      let num = 0
      for (let i = 0; i < seconds; i++) {
        num += randomArr[i % randomArr.length]
      }
      return Date.now() / 10000 + num
    },
    startRolling() {
      this.interval = setInterval(() => {
        this.number = this.initNumber();
      }, 2000);
    },
  },
  mounted() {
    this.startRolling()
  },
  beforeDestroy() {
    this.interval && clearInterval(this.interval)
  }
}
</script>

<style scoped lang="less" src="@/assets/css/jackpot.less"></style>